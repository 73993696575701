import React from "react"
import { PrimaryButton } from "./button"
import Img from "./img"

type Action = {
  link: string
  text: string
}

type CTAProps = {
  title: string
  title2?: string
  actions: Array<Action>
}

const CTABackgroundImg = "cta-background.jpeg"

export function ClosingCTA(props: CTAProps) {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/heroes#component-6364cb4174b8dfdfbd7fa71ac72ab862
  return (
    <div className="relative">
      <div className="absolute inset-0">
        <Img
          className="h-full w-full"
          src={CTABackgroundImg}
          alt="School hallway"
          cover={true}
        />
      </div>
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
          <h2 className="text-3xl font-extrabold tracking-tight text-center text-white sm:text-4xl md:text-5xl">
            <span className="block">{props.title}</span>
            <span className="block">{props.title2}</span>
          </h2>
          <div className="mt-8 flex flex-col sm:flex-row justify-center">
            {props.actions.map(action => (
              <PrimaryButton
                key={`${props.title} ${action.text}`}
                href={action.link}
                text={action.text}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export function MidPageCTA(props: CTAProps) {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/heroes#component-6364cb4174b8dfdfbd7fa71ac72ab862
  return (
    <div className="relative">
      <div className="absolute inset-0">
        <Img
          className="h-full w-full"
          src={CTABackgroundImg}
          alt="School hallway"
          cover={true}
        />
      </div>
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
          <h2 className="text-3xl font-extrabold tracking-tight text-center text-white sm:text-4xl md:text-5xl">
            <span className="block">{props.title}</span>
            <span className="block">{props.title2}</span>
          </h2>
          <div className="mt-8 flex flex-col sm:flex-row justify-center">
            {props.actions.map(action => (
              <PrimaryButton
                key={`${props.title} ${action.text}`}
                href={action.link}
                text={action.text}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClosingCTA

export function SimpleCTA(props: CTAProps) {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/heroes#component-6364cb4174b8dfdfbd7fa71ac72ab862
  return (
    <div className="relative bg-secondary">
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
          <h2 className="text-3xl font-extrabold tracking-tight text-center text-white sm:text-4xl md:text-5xl">
            <span className="block">{props.title}</span>
            <span className="block">{props.title2}</span>
          </h2>
          <div className="mt-8 flex flex-col sm:flex-row justify-center">
            {props.actions.map(action => (
              <PrimaryButton
                key={`${props.title} ${action.text}`}
                href={action.link}
                text={action.text}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
