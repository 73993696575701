import React from "react"
import { Routes } from "../data/routes"
import { useLocation } from "@reach/router"
import { Subjects } from "../data/subjects"
import Img from "./img"

const navigation = [
  { subject: "Home", icon: "", link: Routes.mathResources },
  ...Subjects,
  { subject: "Visit Yup.com", icon: "", link: Routes.home },
]

export default function MathResourceDrawer() {
  // Original Tailwind UI component: https://tailwindui.com/components/application-ui/navigation/sidebar-navigation#component-d00114b9aebe18b49eeac9ab76005e07
  const location = useLocation()
  return (
    <div className="flex flex-col border-r border-gray-900 pt-5 pb-4 bg-white border-opacity-5 overflow-y-auto sm:w-1/2 md:w-1/3 lg:w-1/4">
      <nav className="flex-1 px-2 bg-white space-y-1" aria-label="Sidebar">
        {navigation.map(item => (
          <a
            key={item.subject}
            href={item.link}
            className={`
                ${
                  location.pathname.replace(/\//g, "") ===
                  item.link.replace(/\//g, "")
                    ? "bg-gray-50 text-gray-900 "
                    : "text-gray-600 hover:bg-gray-50 hover:text-gray-900 "
                }
                group flex items-center px-2 py-2 text-sm font-medium rounded-md
              `}
          >
            <div className="mr-3 flex-shrink-0 h-6 w-6">
              {item.icon && <Img src={item.icon} alt={item.subject} />}
            </div>
            <span className="flex-1">{item.subject}</span>
          </a>
        ))}
      </nav>
    </div>
  )
}
