import { Routes } from "../data/routes"

export const Subjects = [
  {
    id: "prealgebra",
    subject: "Pre-Algebra",
    link: `${Routes.mathResources}pre-algebra/`,
    icon: "icon-early-math.svg",
    topics: [
      {
        name: "Integers",
        description:
          "Addition, subtraction, multiplication, prime factorization, exponents",
      },
      {
        name: "Rational Numbers",
        description: "Fractions, decimals, percents",
      },
      {
        name: "Real Numbers",
        description:
          "Absolute value, square roots, properties of addition and multiplication, scientific notation",
      },
      {
        name: "Complex Numbers",
        description: "Imaginary numbers, understanding number classifications",
      },
    ],
    sessions: [
      {
        source: "subjects-prealgebra-1.png",
        target: "https://www.yup.com/session/YXQ9674K13?parent=true",
      },
      {
        source: "subjects-prealgebra-2.jpeg",
        target: "https://www.yup.com/session/YD61MMZ6GR?parent=true",
      },
      {
        source: "subjects-prealgebra-3.jpeg",
        target: "https://www.yup.com/session/JZP3BSRQVX?parent=true",
      },
      {
        source: "subjects-prealgebra-4.jpeg",
        target: "https://www.yup.com/session/VXKP9NLYFS?parent=true",
      },
    ],
  },
  {
    id: "algebra",
    subject: "Algebra",
    link: `${Routes.mathResources}algebra/`,
    icon: "icon-algebra.svg",
    topics: [
      {
        name: "Algebra fundamentals",
        description: "Properties of equality, like terms",
      },
      {
        name: "Linear equations and inequalities",
        description:
          "Solving linear equations and inequalities, properties of lines",
      },
      {
        name: "Systems",
        description:
          "Elimination, substituation, graphing, number of solutions, matrices",
      },
      {
        name: "Functions",
        description:
          "Properties of functions, function operations, types of functions",
      },
      {
        name: "Polynomials",
        description:
          "Polynomial properties, polynomial operations, Rational Root Theorem, binomial expansion",
      },
      {
        name: "Quadratics",
        description:
          "Factoring, completing the square, Quadratic Formula, parabolas",
      },
      {
        name: "Rational functions",
        description:
          "Simplifying rational expressions, solving rational equations, graphing rational equations",
      },
      {
        name: "Exponents",
        description: "Exponent properties",
      },
      {
        name: "Radicals",
        description:
          "Simplifying radical expressions, solving radical equations",
      },
      {
        name: "Exponentials/Logs",
        description:
          "Graphing exponential equations, solving exponential equations, log rules, graphing logs, solving log equations",
      },
      {
        name: "Sequences and series",
        description:
          "Explicit and recursive formulas, geometric sequences, arithmetic sequences, sigma notation",
      },
      {
        name: "Cartesian plane",
        description: "Points, quadrants, distance and midpoint formulas",
      },
      {
        name: "Conic sections",
        description: "Properties of parabolas, ellipses, and hyperbolas",
      },
    ],
    sessions: [
      {
        source: "subjects-algebra-1.jpeg",
        target: "https://www.yup.com/session/6MJG097DFM?parent=true",
      },
      {
        source: "ubjects-algebra-2.jpeg",
        target: "https://www.yup.com/session/ZSJ32CT9QC?parent=true",
      },
      {
        source: "subjects-algebra-3.jpeg",
        target: "https://www.yup.com/session/9695XBHR55?parent=true",
      },
      {
        source: "subjects-algebra-4.jpeg",
        target: "https://www.yup.com/session/30TMYR2RQN?parent=true",
      },
    ],
  },
  {
    id: "geometry",
    subject: "Geometry",
    link: `${Routes.mathResources}geometry/`,
    icon: "icon-geometry.svg",
    topics: [
      {
        name: "Coordinate Geometry",
        description: "Transformations",
      },
      {
        name: "Geometry fundamentals",
        description: "Point, line, plane",
      },
      {
        name: "Angles",
        description: "Transversals, types of langles, measuring angles",
      },
      {
        name: "Triangles",
        description:
          "Types of triangles, triangle properties, centers of triangles, special right triangles",
      },
      {
        name: "Similarity",
        description: "Proportions",
      },
      {
        name: "Proofs",
        description: "Using postulates for proofs",
      },
      {
        name: "Quadrilaterals",
        description: "Types of quadrilaterals",
      },
      {
        name: "2D shapes",
        description: "Finding area and perimeter, regular polygons properties",
      },
      {
        name: "3D shapes",
        description: "Finding volume and surface area, properties of 3D shapes",
      },
      {
        name: "Circles",
        description: "Properties of circles, equations of circles",
      },
      {
        name: "Constructions",
        description: "Compass and straightedge constructions",
      },
    ],
    sessions: [
      {
        source: "subjects-geometry-1.jpeg",
        target: "https://www.yup.com/session/CZ8QY9060Z?parent=true",
      },
      {
        source: "subjects-geometry-2.jpeg",
        target: "https://www.yup.com/session/40L5WHFHKZ?parent=true",
      },
      {
        source: "subjects-geometry-3.jpeg",
        target: "https://www.yup.com/session/9RS3Z9DY42?parent=true",
      },
      {
        source: "subjects-geometry-4.jpeg",
        target: "https://www.yup.com/session/HDJVDW12DK?parent=true",
      },
    ],
  },
  {
    id: "trigonometry",
    subject: "Trigonometry",
    link: `${Routes.mathResources}trigonometry/`,
    icon: "icon-trig.svg",
    topics: [
      {
        name: "Vectors",
        description: "Vector operations, vector properties",
      },
      {
        name: "Right triangle trig",
        description: "SOHCAHTOA, unit circle",
      },
      {
        name: "Trigonometric identities",
        description:
          "Simplifying trig expressions, using identities to evaluate expressions",
      },
      {
        name: "Graphing",
        description: "Properties of trig graphs",
      },
      {
        name: "Solving trigonometric equations",
        description: "Inverse trig, solving equations on different intervals",
      },
    ],
    sessions: [
      {
        source: "subjects-trigonometry-1.jpeg",
        target: "https://www.yup.com/session/NPYWYM114Y?parent=true",
      },
      {
        source: "subjects-trigonometry-2.jpeg",
        target: "https://www.yup.com/session/8WHY803XNP?parent=true",
      },
      {
        source: "subjects-trigonometry-3.jpeg",
        target: "https://www.yup.com/session/0SJV1BH704?parent=true",
      },
      {
        source: "subjects-trigonometry-4.jpeg",
        target: "https://www.yup.com/session/CZV7Y77HHC?parent=true",
      },
    ],
  },
  {
    id: "calculus",
    subject: "Calculus",
    link: `${Routes.mathResources}calculus/`,
    icon: "icon-calculus.svg",
    topics: [
      {
        name: "Limits",
        description: "Evaluating basic limits, L'Hopital's Rule",
      },
      {
        name: "Derivatives",
        description:
          "Derivative rules, critical points, implicit differentiation",
      },
      {
        name: "Integrals",
        description:
          "Finding area under a curve, u-substitution, solids of revolution",
      },
      {
        name: "Differential Equations",
        description: "Slope fields, initial value problems",
      },
    ],
    sessions: [
      {
        source: "subjects-calculus-1.jpeg",
        target: "https://www.yup.com/session/CGZMS5VM2B?parent=true",
      },
      {
        source: "subjects-calculus-2.jpeg",
        target: "https://www.yup.com/session/10S55SW45R?parent=true",
      },
      {
        source: "subjects-calculus-3.jpeg",
        target: "https://www.yup.com/session/PD34VBDC57?parent=true",
      },
      {
        source: "subjects-calculus-4.jpeg",
        target: "https://www.yup.com/session/TR42HPB159?parent=true",
      },
    ],
  },
  {
    id: "statistics",
    subject: "Statistics",
    link: `${Routes.mathResources}statistics/`,
    icon: "icon-probability.svg",
    topics: [
      {
        name: "Basic probability",
        description: "Independent vs dependent, mutual exclusivity",
      },
      {
        name: "Compound probability",
        description:
          "Conditional probability, addition and multiplication rules, expected values",
      },
      {
        name: "Combinatorics",
        description: "Permutations, combinations",
      },
      {
        name: "Distributions",
        description:
          "Measures of central tendency and spread, normal distribution properties, skewness",
      },
      {
        name: "Graphs",
        description:
          "Scatter plots, box and whisker plots, stem and leaf plots, histograms",
      },
      {
        name: "Correlation",
        description: "Line of best fit, correlation vs causation",
      },
      {
        name: "Inferential statistics",
        description:
          "Testing for statistical significance, confidence intervals",
      },
      {
        name: "Research design",
        description: "Sampling, surveys, experiments, observational studies",
      },
    ],
    sessions: [
      {
        source: "subjects-statistics-1.jpeg",
        target: "https://www.yup.com/session/Q88746V4Q8?parent=true",
      },
      {
        source: "subjects-statistics-2.jpeg",
        target: "https://www.yup.com/session/2GQZXQRX4H?parent=true",
      },
      {
        source: "subjects-statistics-3.jpeg",
        target: "https://www.yup.com/session/5CS7XJBM3P?parent=true",
      },
      {
        source: "subjects-statistics-4.jpeg",
        target: "https://www.yup.com/session/MFC7L1QPYY?parent=true",
      },
    ],
  },
]
