import * as React from "react"
import MathResourceDrawer from "../components/MathResourceDrawer"
import { graphql } from "gatsby"
import Cta from "../components/CTA"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Routes } from "../data/routes"

const Content = {
  cta: "Learn what it means to bring Yup to your school or district",
  description: "Learn what it means to bring Yup to your school or district",
  actions: [
    {
      link: Routes.schools,
      text: "Schedule Demo",
    },
  ],
}

function getTitle(title) {
  title = title.replace(/-/g, " ")
  return title.charAt(0).toUpperCase() + title.slice(1)
}

function Header(props) {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/header#component-17d3a4a8538b7d528d954a5db2874da8
  const subjects = props.subjects.map(subject => getTitle(subject)).join(" • ")
  return (
    <div className="relative bg-secondary-800">
      <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
          {getTitle(props.title)}
        </h1>
        <p className="mt-6 text-xl text-gray-100 max-w-3xl">{subjects}</p>
      </div>
    </div>
  )
}

const MathResourceTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || "Concept"

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={getTitle(post.frontmatter.title)}
        route={`${Routes.mathResources}${post.frontmatter.title}`}
        description={`Get a concise description of ${getTitle(
          post.frontmatter.title
        )} in just a single paragraph or picture. You can also learn about Yup's 24/7 on-demand math tutoring.`}
      />
      <div className="sm:flex max-w-7xl mx-auto">
        <MathResourceDrawer />
        <div className="flex-grow">
          <Header
            title={post.frontmatter.title}
            subjects={post.frontmatter.subjects}
          />

          <div className="relative px-4 sm:px-6 lg:px-8 py-8">
            <div className="my-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
              <article
                className="math-resource"
                itemScope
                itemType="http://schema.org/Article"
              >
                <section
                  dangerouslySetInnerHTML={{ __html: post.html }}
                  itemProp="articleBody"
                />
              </article>
            </div>
          </div>
          <Cta title={Content.cta} actions={Content.actions} />
        </div>
      </div>
    </Layout>
  )
}

export default MathResourceTemplate

export const pageQuery = graphql`
  query MathResourceBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        subjects
      }
    }
  }
`
